import Button, { ButtonProps } from "@material-ui/core/Button"

export function ToastActionButton({
  text,
  onClick,
  ...props
}: {
  text: string
  onClick: () => void
} & ButtonProps) {
  return (
    <Button color="secondary" size="small" onClick={onClick} {...props}>
      {text}
    </Button>
  )
}
